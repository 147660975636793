body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: rgb(22 135 167 / 80%)!important; */
  background: #276678 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
div[role="dialog"]{
  padding:0;
}
.modal-v1{
    background: #E5E5E5;
    border-radius: 0 !important;
    padding: 45px 60px;
    text-align: center;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.07), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
      
}
.modal-v1>div > div:first-of-type{
  margin-bottom: 2px!important;
}